<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
        variant="primary"
        @click="openModal(null, 'Create','Create new news', filterItems)"
        v-if="resolvePermission('news.create')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          label="Basic Input"
          label-for="selectInput"
        >
          <b-form-select
            id='selectInput'
            v-model="selected"
            :options="options"
          />
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col> -->
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems('Search')"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="newsList" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(title)="data">
        <div
          class="text-truncate text-nowrap no-margins"
          style="max-width: 200px"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(image)="data">
        <img
          :src="data.item.image_url ? data.item.image_url : placeHolder"
          width="40"
          height="38"
          class="rounded"
          style="object-fit: cover"
        />
      </template>
      <template #cell(descriptions)="data">
        <div
          class="text-truncate text-nowrap no-margins"
          v-html="data.value ? data.value.substring(0, 50) + (data.value.length>50?'...':''):''"
        ></div>
      </template>

      <template #cell(status)="data">
        <div class="text-center">
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <!-- <template #cell(other_info.important)="data">
        <div class="text-center">
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template> -->
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('news.edit')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="resolvePermission('news.delete')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p class="text-center py-4" v-if="newsList.length == 0">
      {{ $t("no") + $t($route.name) + $t("noDataHint") }}
    </p>
    <b-card-footer>
      <b-pagination
      v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      @input="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "image",
          label: this.$t("Image"),
        },
        {
          key: "title",
          label: this.$t("News Title"),
          formatter: (value)=> {
            if(!value || value === 'null'|| value === 'false' || value === 'undefined'){
              return this.$t(this.$i18n.locale) +" "+ this.$t("Not activated");
            }else{
              return value
            }
          }
        },
        {
          key: "descriptions",
          label: this.$t("Description"),
          formatter: (value, key, item) => value[this.$i18n.locale],
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        // {
        //   key: "other_info.important",
        //   label: this.$t("Improtant News"),
        // },
        // {
        //   key: "ord",
        //   label: this.$t("ord"),
        // },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
    };
  },

  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.filterItems();
  },

  methods: {
    ...mapActions(["getPages", "loadNewsList", "deleteNews"]),
    filterItems() {
        this.loadNewsList({
          page: this.currentPage,
          keywords: this.searchText,
        }).then((res) => {
          this.rows = res.data.results.total;
        });
    },
    editItem(row) {
      this.openModal(row, "Edit","Edit news", this.filterItems);
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteNews, row);
    },
  },

  computed: {
    ...mapGetters(["newsList"]),

  },
};
</script>
